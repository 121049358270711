@import '../../variables.scss';

.title {
  font-weight: normal;
  font-size: 2rem;

  &.default {
    color: $title-color-default;
  }

  &.inverse {
    color: $title-color-inverse;
  }

  &.strong {
    font-weight: bold;
  }
}
