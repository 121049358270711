.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  &.full {
    align-items: stretch;
    height: 100%;
  }

  &.center {
    align-items: center;
  }

  @media (min-width: 800px) {
    &.reverse {
      flex-direction: row-reverse;
    }
  }
}
