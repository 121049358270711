.gest {
  width: 350px;

  @media screen and (max-width: 800px) {
    padding-top: 50px;
    height: 200px;
    margin: auto;
  }

  @media (max-width: 420px) {
    max-width: 250px;
  }
}
