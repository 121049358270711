.view-image {
  cursor: pointer;
  padding: 10px;
}

.slick-arrow {
  width: 50px;
  height: 50px;
  &:before {
    color: #000;
    font-size: 50px;
  }
}
.slick-prev {
  left: -70px;
}
.slick-next {
  right: -70px;
}
@media (max-width: 850px) {
  .slick-arrow {
    width: 35px;
    height: 35px;
    &:before {
      color: #000;
      font-size: 35px;
    }
  }
  .slick-prev {
    left: -35px;
  }
  .slick-next {
    right: -35px;
  }
}
@media (max-width: 420px) {
  .slick-arrow {
    width: 25px;
    height: 25px;
    &:before {
      color: #000;
      font-size: 25px;
    }
  }
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
}
