$font-size-base: 16px;

$primary-color: #08c6e6;
$primary-inverse: #f8fafd;

$secondary-color: #677897;
$secondary-inverse: #fff;

$text-text: #677897;
$background-color: #f8fafd;

$title-color-default: $secondary-color;
$title-color-inverse: $secondary-inverse;

$social-background: $secondary-color;
$social-icon: $secondary-inverse;
