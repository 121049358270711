@import '../../variables.scss';

.copyright {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 0.8rem;
  color: $primary-inverse;
  background: $primary-color;
}
