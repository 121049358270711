@import '../../variables.scss';

.social {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  background: url(../../icons/icons.png) 0 0 no-repeat;
  transition: transform 200ms ease 0s;
  border-radius: 50%;

  &:hover {
    transform: translate(0, -5px);
  }

  > span {
    display: none;
  }

  &.facebook {
    background-position: 0 0;
  }
  &.instagram {
    background-position: -30px 0;
  }
  &.twitter {
    background-position: -60px 0;
  }
  &.youtube {
    background-position: -90px 0;
  }
  &.linkedin {
    background-position: -120px 0;
  }
  &.medium {
    background-position: -150px 0;
  }
  &.spotify {
    background-position: -210px 0px;
  }
}
