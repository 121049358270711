@import '../../variables.scss';

.form-control {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px 15px;
  color: inherit;
  border: #ccc 2px solid;
  background: #fff;
  border-radius: 4px;
  -webkit-appearance: none;

  @at-root textarea#{&} {
    resize: none;
  }

  &:focus {
    outline: none;
    border-color: $primary-color;
  }
  &:invalid {
    box-shadow: none;
  }
}
