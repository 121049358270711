@import '../../variables.scss';

.close {
  display: flex;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  border: none;
  background: transparent url(../../images/button-hubshop.png) center no-repeat;
  background-size: 34px;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
