@import './variables.scss';

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body,
html,
#root {
  height: 100%;
}

html {
  font-family: sans-serif;
  font-size: $font-size-base;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: $text-text;
  background: $background-color;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

link {
  text-decoration: none;
  color: inherit;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #cbe3fa;
}
