@import '../../variables.scss';

.button {
  padding: 10px 30px;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
  color: $primary-inverse;
  background-image: linear-gradient(
    to top,
    darken($primary-color, 5%),
    $primary-color
  );
  border: none;
  border-radius: 100px;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
