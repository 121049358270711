.col {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @media (min-width: 800px) {
    &.col-12 {
      flex: 1;
    }

    &.col-6 {
      flex: 0 0 50%;
    }

    &.col-4 {
      flex: 0 0 33.333%;
    }
  }
}
