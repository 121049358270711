@import '../../variables.scss';

.question {
  margin: 15px 0;
}

.question-header {
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
  cursor: pointer;
  color: $primary-color;
  background: lighten($primary-color, 27%);
  border-radius: 500px;
}

.answer {
  max-height: 0;
  overflow: hidden;
  font-size: 0.9rem;
  opacity: 0;
  transition: all 0.3s ease;

  &.visible {
    padding: 1rem;
    max-height: none;
    opacity: 1;
  }
}
