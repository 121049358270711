.container {
  width: 100%;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  &.full {
    height: 100%;
  }
}
