@import '../../variables.scss';

.modal {
  max-width: 600px;
  max-height: 500px;
  padding: 20px;
  margin: auto;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: visible !important;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
