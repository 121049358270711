@import '../../variables.scss';

.header {
  width: 100%;
  height: 400px;
  position: relative;
  color: $primary-inverse;
  background: $primary-color top center no-repeat;
  background-size: cover;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 800px) {
  .logo {
    width: 305px;
    height: 115px;
  }
}

.header-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    rgba($primary-color, 0.3),
    transparent
  );
}
