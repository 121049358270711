.footer {
  padding-top: 30px;
}

.wrapper-contact {
  padding: 30px 0;

  @media (max-width: 800px) {
    .col {
      text-align: center !important;
    }

    .button {
      margin-top: 15px;
    }
  }
}
@media (max-width: 800px) {
  .iconFooter {
    width: 150px;
    margin: auto;
  }
  .contactFooter {
    text-align: center;
  }
}
